import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import AboutMeBlock from "../components/aboutMeBlock"

import "../styles/about.scss"

const About = ({ location }) => {
  const data = useStaticQuery(graphql`
    query BioImageQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 150, height: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          social {
            youtube
          }
        }
      }
    }
  `)
  const { social } = data.site.siteMetadata
  if (typeof window !== "undefined" && typeof document !== "undefined") {
    ;(function() {
      // Vertical Timeline - by CodyHouse.co
      function VerticalTimeline(element) {
        this.element = element
        this.blocks = this.element.getElementsByClassName("cd-timeline__block")
        this.images = this.element.getElementsByClassName("cd-timeline__img")
        this.contents = this.element.getElementsByClassName(
          "cd-timeline__content"
        )
        this.offset = 0.8
        this.hideBlocks()
      }

      VerticalTimeline.prototype.hideBlocks = function() {
        if (!("classList" in document.documentElement)) {
          return // no animation on older browsers
        }
        //hide timeline blocks which are outside the viewport
        var self = this
        for (var i = 0; i < this.blocks.length; i++) {
          ;(function(i) {
            if (
              self.blocks[i].getBoundingClientRect().top >
              window.innerHeight * self.offset
            ) {
              self.images[i].classList.add("cd-timeline__img--hidden")
              self.contents[i].classList.add("cd-timeline__content--hidden")
            }
          })(i)
        }
      }

      VerticalTimeline.prototype.showBlocks = function() {
        if (!("classList" in document.documentElement)) {
          return
        }
        var self = this
        for (var i = 0; i < this.blocks.length; i++) {
          ;(function(i) {
            if (
              self.contents[i].classList.contains(
                "cd-timeline__content--hidden"
              ) &&
              self.blocks[i].getBoundingClientRect().top <=
                window.innerHeight * self.offset
            ) {
              // add bounce-in animation
              self.images[i].classList.add("cd-timeline__img--bounce-in")
              self.contents[i].classList.add("cd-timeline__content--bounce-in")
              self.images[i].classList.remove("cd-timeline__img--hidden")
              self.contents[i].classList.remove("cd-timeline__content--hidden")
            }
          })(i)
        }
      }

      var verticalTimelines = document.getElementsByClassName("js-cd-timeline"),
        verticalTimelinesArray = [],
        scrolling = false
      if (verticalTimelines.length > 0) {
        for (var i = 0; i < verticalTimelines.length; i++) {
          ;(function(i) {
            verticalTimelinesArray.push(
              new VerticalTimeline(verticalTimelines[i])
            )
          })(i)
        }

        //show timeline blocks on scrolling
        window.addEventListener("scroll", function(event) {
          if (!scrolling) {
            scrolling = true
            !window.requestAnimationFrame
              ? setTimeout(checkTimelineScroll, 250)
              : window.requestAnimationFrame(checkTimelineScroll)
          }
        })
      }

      function checkTimelineScroll() {
        verticalTimelinesArray.forEach(function(timeline) {
          timeline.showBlocks()
        })
        scrolling = false
      }
    })()
  }

  const aboutMeStory = [
    // {
    //   icon: "copyCreator",
    //   headline: "Showing How It's Done",
    //   text: (
    //     <span>
    //       After having built a few SaaS companies, I decided it would be awesome
    //       to start another! But this time, I document the entire process on my
    //       YouTube channel for the world to watch. So I started{" "}
    //       <a
    //         target="_blank"
    //         rel="noopener noreferrer"
    //         href={`https://copycreator.co/?utm_source=millionairemillennial&utm_medium=home_page`}
    //       >
    //         CopyCreator.co
    //       </a>{" "}
    //       and am doumenting the entire process of starting a SaaS with $0{" "}
    //       <a
    //         target="_blank"
    //         rel="noopener noreferrer"
    //         href={`https://www.youtube.com/playlist?list=PLqIBMkc9P61B0tZcIpjUnkRDqk-3cCPT5`}
    //       >
    //         right here on YouTube.
    //       </a>
    //     </span>
    //   ),
    //   date: "August 2020",
    // },
    {
      icon: "mm",
      headline: "Millionaire Millennial Academy",
      text: (
        <span>
          Out of a desire to teach and supply tools to aspiring entrepreneurs, I
          started this site in hopes of providing business owners the knowledge
          and toolset required to achieve the success they deeply desire.
        </span>
      ),
      date: "May 2020",
    },
    {
      icon: "hand-holding-usd",
      headline: "Big Exit",
      text: (
        <span>
          Sold Go Atlas for <b>$365,000</b> - the biggest single transaction I'd
          ever received.
        </span>
      ),
      date: "March 2020",
    },
    {
      icon: "bed",
      headline: "Hiatus",
      text: (
        <span>
          After hiring enough employees to run all of Atlas, I spent time
          relaxing and enjoying life. Read many many books, traveled a bit, and
          just enjoyed the little things everyday. New perspective on happiness
          and fulfillment.
        </span>
      ),
      date: "September 2019",
    },
    {
      icon: "atlas",
      headline: "Launched Atlas Labs",
      text: (
        <span>
          After spending months on the grinding wheel, I learned to code and
          build my software management software myself. Who needs devs anyway?
          <br />
          <br />
          This software company became{" "}
          <a
            target="_blank"
            rel="noopener noreferrer nofollow"
            href={`https://go-atlas.io`}
          >
            Go Atlas
          </a>{" "}
          and has helped thousands of sellers get started selling on Amazon.
        </span>
      ),
      date: "March 2019",
    },
    {
      icon: "tired",
      headline: "You Want Something Done Right...",
      text: (
        <span>
          Despite the devs I hired absolutly sucking and not building anything
          useful, I still ended the year with over $1MM overall. I fired the
          devs and decided I needed to take matters into my own hands.
        </span>
      ),
      date: "December 2018",
    },
    {
      icon: "cookie-bite",
      headline: "Biting Off Too Much",
      text: (
        <span>
          Hired a software agency to build an inventory management software for
          the many Amazon sellers I now knew. I figured this would be very
          helpful for them. I also broke into wholesale more, expanded my
          private label brands, and hired more employees.
        </span>
      ),
      date: "April 2018",
    },
    {
      icon: "user-plus",
      headline: "Growing the Team",
      text: (
        <span>
          Hired my best friend to come work for me sourcing new products for
          Amazon. Had my first 6 figure month.
        </span>
      ),
      date: "February 2018",
    },
    {
      icon: "glass-cheers",
      headline: "Celebrated Success",
      text: (
        <span>
          Turned 24 and celebrated my new success by paying for me and all my
          friends to fly out to Miami and party down for a weekend. Also moved
          to a highrise and bought a supercar. Ya know - young money stuff.
        </span>
      ),
      date: "September 2017",
    },
    {
      icon: "chalkboard-teacher",
      headline: "Began Mentoring",
      text: `After thousands of new subscribers on YouTube ask for consulting and courses, 
      I release an all inclusive Amazon FBA mentoring program. Expecting to only make a few thousand 
      dollars from my many months of work on this, I ended up bringing in $10k the first week.`,
      date: "Jun 2017",
    },
    {
      icon: "grin-tongue-squint",
      headline: "Quit My Job",
      text: (
        <div>
          Contrary to the advice of everyone in my life, I decided to quit my
          job and do Amazon FBA fulltime. I also start sharing my journey on{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://www.youtube.com/channel/${social.youtube}`}
          >
            YouTube
          </a>
          .
        </div>
      ),
      date: "January 2017",
    },
    {
      icon: "comment-dollar",
      headline: "Had a Great Q4",
      text:
        "Ended up making 5x my engineering income in Q4 on Amazon. I guess this is the real deal.",
      date: "December 2016",
    },
    {
      icon: "briefcase",
      headline: "Decided on a Business Model",
      text:
        "After spending months researching I stumble upon 'Amazon FBA' and it seems very promising.",
      date: "September 2016",
    },
    {
      icon: "truck-loading",
      headline: "Moved to Dallas",
      text: (
        <span>
          Moved to the Big D and started a job as an engineer at a small
          consulting firm.
          <br />
          <br /> Realized there must be more to life than working hard so my
          boss can build a mansion in Cabo, I decided to start making money
          online... But HOW?
        </span>
      ),
      date: "June 2016",
    },
    {
      icon: "graduation-cap",
      headline: "Graducated College",
      text: "Hopped out of A&M with a degree in ELEN. #FreshGrad",
      date: "May 2016",
    },
  ]

  return (
    <Layout location={location}>
      <SEO title="About" description="My journey thus far." />
      <div className="about-me">
        <Image
          fixed={data.avatar.childImageSharp.fixed}
          alt={"Jordan"}
          style={{
            marginBottom: 0,
            minWidth: 50,
            borderRadius: `100%`,
          }}
        />
        <div>
          <h3>About Jordan, the Millionaire Millennial</h3>
          <p>
            Hey! I'm Jordan 👋. I've started and grown
            many different online businesses over the past several years. I'm
            now semi-retired and enjoy teaching and working on various
            projects.
            {/* <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://www.youtube.com/channel/${social.youtube}`}
            >
              YouTube channel.
            </a> */}
          </p>
        </div>
      </div>
      <div className="timeline-intro">
        <b>Below is his timeline of success dating back to his college days.</b>
        <br />
        <a href="#end" className="raised-button">
          Scroll to the Beginning
        </a>
      </div>
      <section className="cd-timeline js-cd-timeline">
        <div className="container max-width-lg cd-timeline__container">
          {aboutMeStory.map(({ icon, headline, text, date }, i) => (
            <AboutMeBlock
              key={i}
              icon={icon}
              headline={headline}
              text={text}
              date={date}
            />
          ))}
        </div>
      </section>
      <div id="end"></div>
    </Layout>
  )
}

export default About
