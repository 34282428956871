import React from "react"

import "../utils/fontawesome"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default ({ icon, headline, text, date }) => {
  const randomColorRGB = getRandomRgb()
  const randomColor = `rgb(${randomColorRGB[0]},${randomColorRGB[1]},${randomColorRGB[2]})`
  const contrastColor = idealTextColor(randomColorRGB)
  return (
    <div className="cd-timeline__block">
      <div
        className="cd-timeline__img"
        style={{
          backgroundColor:
            icon === "atlas"
              ? "black"
              : icon === "mm"
              ? "white"
              : randomColor,
        }}
      >
        {icon === "atlas" ? (
          atlasLogo
        ) : icon === "mm" ? (
          mmLogo
        ) : (
          <FontAwesomeIcon color={contrastColor} icon={icon} />
        )}
      </div>

      <div className="cd-timeline__content text-component">
        <h2>{headline}</h2>
        <span className="color-contrast-medium">{text}</span>

        <div className="date-holder">
          <span className="cd-timeline__date">{date}</span>
        </div>
      </div>
    </div>
  )
}

function getRandomRgb() {
  var num = Math.round(0xffffff * Math.random())
  var r = num >> 16
  var g = (num >> 8) & 255
  var b = num & 255
  return [r, g, b]
}

const idealTextColor = ([a, b, c]) =>
  150 < a * 0.299 + b * 0.587 + c * 0.114 ? "black" : "white"

const atlasLogo = (
  <svg
    className="nc-icon glyph"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="40px"
    height="40px"
    viewBox="0 0 69 69"
  >
    <title>Atlas Logo</title>
    <path
      d="M26.6,9.5,25.3,6.3A30.5,30.5,0,0,0,3.1,35.7,29.8,29.8,0,0,0,6.8,50.2l1.8-4.1A27.4,27.4,0,0,1,6.8,35.7,27.1,27.1,0,0,1,26.6,9.5Z"
      fill="#8bd9ed"
    ></path>
    <path
      d="M47.7,59.2h-.1A26.4,26.4,0,0,1,34,62.9a25.3,25.3,0,0,1-8.3-1.3H17.2a30.2,30.2,0,0,0,16.8,5,29.9,29.9,0,0,0,15.1-4h.1Z"
      fill="#8bd9ed"
    ></path>
    <path
      d="M41.8,5.9l1.9,4.5a27.1,27.1,0,0,1,15.2,36l1.9,4.5a29.9,29.9,0,0,0,4-15.1A30.9,30.9,0,0,0,41.8,5.9Z"
      fill="#029cea"
    ></path>
    <path
      d="M59,53.5a31.4,31.4,0,0,1-7.6,7.4L28,5.7a29.1,29.1,0,0,1,6-.6,22.8,22.8,0,0,1,4.5.4l.4.8Z"
      fill="#8bd9ed"
    ></path>
    <polygon
      points="46.3 35 46.3 46.3 45.3 46.3 45.3 45.7 28.4 45.7 32.3 36.5 41 36.5 41 35 46.3 35"
      fill="#8bd9ed"
    ></polygon>
    <polygon
      points="36.6 26.9 36.6 27 36.6 26.9 36.6 26.9"
      fill="#029cea"
    ></polygon>
    <path
      d="M45.6,59.5H14.2a28.5,28.5,0,0,1-5.9-6.6L25.5,12.3l5.9,13.9L23.5,44.8l-2.2,5.4-1,2.3,4.6,2,1.9-4.3H41.6Z"
      fill="#029cea"
    ></path>
  </svg>
)

const mmLogo = (

<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="1350pt" height="1350pt" viewBox="-200 -200 1700 1700"
 preserveAspectRatio="xMidYMid meet">
<g transform="translate(0,1350) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M1110 10471 l0 -2521 390 0 390 0 2 1588 3 1587 22 35 c39 64 78 84
158 84 l70 0 853 -617 c502 -363 858 -614 865 -610 7 4 386 277 842 607 457
330 848 606 870 614 83 29 172 -5 215 -80 l25 -43 3 -1582 2 -1583 390 0 390
0 0 2521 c0 2013 -3 2519 -12 2513 -7 -4 -605 -457 -1329 -1005 -1057 -802
-1325 -1000 -1361 -1009 -54 -14 -115 -1 -162 35 -17 12 -610 461 -1317 997
-707 536 -1291 978 -1297 982 -9 5 -12 -616 -12 -2513z"/>
<path d="M6780 4971 l0 -2801 575 0 575 0 2 1650 3 1650 885 -640 c486 -352
887 -640 890 -640 3 0 403 289 890 641 l885 640 3 -1651 2 -1650 575 0 575 0
0 2800 c0 1540 -2 2800 -4 2800 -3 0 -658 -495 -1456 -1101 -798 -605 -1457
-1102 -1464 -1105 -7 -3 -665 489 -1462 1093 -797 605 -1455 1102 -1461 1107
-10 6 -13 -556 -13 -2793z m1732 1187 c1001 -758 1092 -825 1141 -835 53 -11
142 0 179 23 10 6 1073 811 2491 1886 l77 59 0 -2441 0 -2440 -335 0 -335 0 0
1532 c0 1172 -3 1546 -12 1592 -11 52 -20 68 -63 111 -41 42 -59 52 -106 62
-75 15 -152 -3 -222 -53 -28 -20 -400 -289 -825 -596 -424 -307 -778 -561
-786 -564 -7 -3 -381 261 -847 598 -759 548 -839 604 -889 614 -90 19 -153 1
-215 -61 -41 -40 -53 -61 -63 -103 -9 -41 -12 -422 -12 -1593 l0 -1539 -335 0
-335 0 0 2440 0 2440 203 -153 c111 -85 691 -525 1289 -979z"/>
<path d="M3125 1845 c-240 -45 -445 -203 -560 -433 -55 -111 -77 -206 -77
-347 -1 -149 16 -224 84 -364 44 -91 61 -116 141 -196 143 -143 281 -212 472
-235 370 -45 729 183 850 541 14 40 25 76 25 81 0 4 -52 8 -115 8 -101 0 -116
-2 -122 -17 -28 -85 -84 -174 -148 -238 -113 -113 -238 -165 -395 -165 -106 1
-155 12 -249 57 -283 136 -395 519 -239 816 39 76 153 192 225 230 66 35 191
67 262 67 139 -1 291 -64 404 -170 l72 -68 70 50 c113 79 113 66 9 166 -49 48
-116 102 -147 121 -161 95 -374 131 -562 96z"/>
<path d="M6000 1060 l0 -772 378 4 c347 4 383 6 456 26 265 73 466 257 553
507 26 74 27 91 27 235 0 141 -2 162 -27 235 -67 201 -219 367 -419 459 -146
68 -153 69 -580 73 l-388 4 0 -771z m735 565 c172 -37 313 -143 386 -290 66
-134 82 -251 53 -393 -42 -208 -171 -355 -374 -426 -80 -28 -96 -30 -277 -34
-105 -2 -207 -2 -227 2 -70 13 -66 -21 -66 574 0 295 4 542 8 548 19 29 52 33
237 34 130 0 212 -5 260 -15z"/>
<path d="M7790 1060 l0 -770 455 0 455 0 0 94 0 95 -325 3 c-296 3 -326 5
-342 21 -16 16 -18 40 -18 247 0 207 2 231 18 247 16 16 45 18 312 21 l295 3
0 94 0 95 -289 0 c-269 0 -290 1 -312 19 -24 19 -24 22 -24 195 0 155 2 177
18 193 16 16 43 18 267 21 l250 3 0 94 0 95 -380 0 -380 0 0 -770z"/>
<path d="M11013 1793 c47 -74 614 -1008 626 -1030 7 -14 11 -97 11 -247 l0
-226 110 0 109 0 3 247 3 248 313 518 c171 284 312 519 312 522 0 3 -49 5
-109 5 l-109 0 -234 -395 c-253 -428 -262 -439 -314 -399 -14 10 -128 192
-254 404 l-229 385 -131 3 -131 3 24 -38z"/>
<path d="M9060 1046 l0 -756 115 0 115 0 0 471 c0 314 4 477 11 490 13 25 48
39 75 29 11 -5 130 -87 263 -184 134 -97 247 -176 250 -176 3 1 118 82 255
180 201 146 254 180 281 180 61 0 60 13 63 -512 l3 -478 109 0 110 0 0 750 c0
413 -2 750 -5 750 -3 0 -181 -133 -395 -296 -393 -297 -419 -314 -461 -288
-11 7 -736 555 -776 587 -10 7 -13 -143 -13 -747z"/>
<path d="M1308 1217 c-163 -315 -338 -652 -388 -750 l-92 -177 111 0 110 0 53
107 c42 85 58 109 78 115 33 8 798 8 830 -1 19 -5 37 -30 80 -114 l55 -107
118 0 c64 0 117 2 117 5 0 5 -769 1487 -774 1492 -1 1 -135 -255 -298 -570z
m459 -157 c72 -146 132 -277 132 -292 2 -57 -5 -58 -304 -58 -299 0 -305 1
-305 58 0 28 244 542 263 554 7 5 28 7 47 6 l35 -3 132 -265z"/>
<path d="M4533 1042 c-211 -406 -383 -742 -383 -745 0 -4 49 -7 108 -7 l108 0
49 103 c34 70 58 106 75 114 32 16 792 18 836 3 24 -9 39 -30 82 -115 l52
-105 121 0 121 0 -64 123 c-35 67 -210 402 -388 745 -178 342 -326 622 -329
622 -3 0 -178 -332 -388 -738z m413 280 c13 -8 91 -161 200 -391 62 -132 71
-157 63 -180 -6 -14 -16 -29 -22 -33 -7 -4 -132 -8 -279 -8 -284 0 -287 1
-303 49 -4 13 36 107 121 283 69 145 131 269 136 276 11 13 66 16 84 4z"/>
</g>
</svg>


)
